import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CLINICS 開発チームで横軸の取り組みをした話",
  "date": "2020-08-14T04:55:16.000Z",
  "slug": "entry/2020/08/14/135516",
  "tags": ["medley"],
  "hero": "./2020_08_14.png",
  "heroAlt": "横軸の取り組み"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、19 新卒の桶谷です。現在は CLINICS で検査周りの開発を担当しています。`}</p>
    <p>{`最近は、私自身が基礎疾患持ちということもあり、社会情勢を考慮してフルリモートで業務に勤しんでいます。オンラインコミュニケーションは対面と比べ、意識しなければならない点が多く、慣れが必要であると日々感じています。少しでも対面に近い環境を実現するために、一眼レフカメラを Web カメラとして代用したり、ダイナミックマイクを使用してクリアな音声を届けられるように作業環境の改善に取り組んでいます。`}</p>
    <p>{`今回は CLINICS 開発チームで課題となっていた「オンボーディングやキャッチアップに時間がかかる問題」を解決するために行った、横軸の取り組みについて紹介します。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`CLINICS 開発チームではクラウド診療支援システム「`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{`」の機能として主に電子カルテ、オンライン診療、予約システムなどの機能の開発を行っています。医療システムの開発において医療業務知識は必要不可欠であり、例えば「会計」や「検査」といった医療機関で行われる業務に関する理解が要求されます。そのため、各々が日々キャッチアップに勤しんでいる一方、ひとつひとつの業務が複雑であるために、複数の機能を横断したプロダクト理解、全体把握が難しい課題がありました。そこで、この問題を解決するべく「横軸勉強会」と題して、CLINICS のドメイン知識共有会を行うことにしました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200812/20200812191102.png",
      "alt": "20200812191102.png"
    }}></img>
    <h1>{`ドメイン知識共有会の概要`}</h1>
    <p>{`ドメイン知識共有会は各々が携わった中で蓄積してきた業務知識や開発の背景を共有する会です。本会は毎週 1 時間トピックに沿ってオンライン(Google Meet)で実施します。あらかじめトピック、発表者、ファシリテーターを指定し、発表者には簡単な資料を用意してもらいます。加えて参加者には以下のことを意識してもらいました。`}</p>
    <ul>
      <li parentName="ul">{`発表者は対話型の発表を意識しフラットな雰囲気をつくる`}</li>
      <li parentName="ul">{`参加者はチャットを用いて思ったことをつぶやく`}</li>
    </ul>
    <p>{`各トピックは医療システムを開発する上で必要なドメイン知識を切り口として、技術寄りの内容となっています。発表者によっては勉強会後の宿題が用意されたり、おすすめの本紹介があったりと多種多様です。発表形式をあえて定めないことで、各回ごとに発表者の個性が出るというのも面白みの一つだと思います。特に宿題があった回では、後日にフィードバック会が設けられ知識の定着に繋がりました。`}</p>
    <p><em parentName="p">{`実際に行ったトピック`}</em></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200812/20200812184411.png",
      "alt": "20200812184411.png"
    }}></img>
    <p><em parentName="p">{`実際の様子`}</em></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200812/20200812201630.png",
      "alt": "20200812201630.png"
    }}></img>
    <h1>{`ドメイン知識共有会を終えて`}</h1>
    <p>{`今回ドメイン知識共有会を通して、機能の実装背景や概要を幅広く知ることができました。「会計業務の複雑さ」や「受付から診察までの動線設計」などのこれまでは共有する機会がなかった個々が持っている知識・知見を学ぶことができ、チーム全体でドメイン知識に関する認識合わせをする良い機会となりました。`}</p>
    <p>{`最後に勉強会の振り返りを行ったところ、以下のような意見がありました。`}</p>
    <ul>
      <li parentName="ul">{`ドメイン知識共有会を通してチーム力が上がってきている`}</li>
      <li parentName="ul">{`勘違いしていた知識を訂正できた`}</li>
      <li parentName="ul">{`相互理解が深まりチーム全体としてコミュニケーションのハードルを下げることができた`}</li>
      <li parentName="ul">{`各専門領域に関するドキュメントを一箇所にまとめることができた`}</li>
    </ul>
    <p>{`ポジティブな意見が多く、取り組みの結果としてチームの基礎力向上にも繋がったと感じています。加えて、ドメイン知識共有会用に作成した資料はドキュメントとして残るため、オンボーディング資料などに活用ができそうです。しかしながら、以下のような問題もあります。`}</p>
    <ul>
      <li parentName="ul">{`発表者の準備コストが高い`}</li>
      <li parentName="ul">{`概要説明で終わってしまい即日業務で使用できる内容ではない`}</li>
      <li parentName="ul">{`学んだことを深堀りできていないため知識の定着に繋がらない`}</li>
    </ul>
    <p>{`上記については、例えば発表者を 2 人ペアにして準備コストを分散させたり、宿題を出して次回にフィードバックする、開発チーム以外も気軽に参加ができるようにライトなトピックを追加するなど、今後のドメイン知識共有会のなかで改善していきたいと思います。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回は CLINICS 開発チームで行った横軸の取り組みについて紹介させていただきました。`}</p>
    <p>{`横軸でお互いをフォローしあうような「情報共有の場」を作ることで、より良い組織になっていくと感じています。今後、さらにプロダクトの機能が増えるとともに事業部全体として、関わるメンバーが増えるため、横軸での知識共有の仕組みは事業部を含めて必要であると考えています。開発チームに限らずに、プロダクトに関わるメンバーを巻き込み、組織コミュニケーションの改善を続けていきたいです。`}</p>
    <p>{`最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      